<template>
  <van-nav-bar
  title="修改密码"
  left-arrow
  @click-left="onGoBack"
  />
  <div>
    <van-form @submit="modifypassword" class='register'>
      <van-field
        v-model="oldpw"
        type="password"
        name="oldpw"
        size=large
        label="原密码："
        placeholder="输入原密码"
        :rules="[{ required: true, message: '请输入原密码' }]"
        />
      <van-field
        v-model="newpd"
        type="password"
        name="newpd"
        size=large
        label="新密码："
        placeholder="输入6位以上密码"
        :rules="[{ validator:validatorpassword,required: true, message: '请输入密码' }]"
        />
      <van-field
        v-model="renewpd"
        type="password"
        name="renewpd"
        size=large
        label="确认密码："
        placeholder="输入6位以上密码"
        :rules="[{ validator:reconfigpassword,required: true, message: '请再输入密码一次！' }]"
      />
      <span style="margin:10px;color:red">注意：密码不能设置为123456，否则不能登录系统。</span>
    <van-button :disabled='isdisable' :loading='isloading' type="primary" round block style="width:50%;margin:50px auto 0 auto" native-type="submit" >保存修改</van-button>
    </van-form>
  </div>
</template>

<script>
import { ref,reactive,getCurrentInstance,onMounted} from 'vue';
import { useRouter,useRoute } from 'vue-router'
import { Toast } from 'vant';
import { useUserStore } from '@/store/user.ts'

const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Toast.name]: Toast,
  },

  setup() {
    const  oldpw=ref('');
    const  newpd = ref('');
    const  renewpd = ref('');
    
    const  isdisable = ref(false);
    const  isloading = ref(false);
   
   const router = useRouter()
    const validatorpassword=()=>{
      if(newpd.value.length < 6 ){
        return "请输入6位以上密码！"
      }
      if(newpd.value=='123456'){
        return "密码不能为123456，请修改！"
      }
    };
      
    const reconfigpassword=()=>{
      if(newpd.value !== renewpd.value){
        return "两次密码不一致，重新输入！"
      }
    };

    let { proxy } = getCurrentInstance();
    const userStore = useUserStore()
    
    const modifypassword=()=>{
      
      isdisable.value = true;
      isloading.value = true;

      let params = {data: {}}
      params={
        phoneNum:userStore.userInfo.phone,
        oldPwd:oldpw.value,
        newPwd:newpd.value
      }
      proxy.$axios.post(base_url+'/user/changeUserPwd',params)
      .then(res=>{
        if(res.data.code==200){
          Toast(res.data.msg);
          router.push('/home');
        }else{
           Toast('密码修改失败，请确认原密码是否正确。');
           oldpw.value='';
           newpd.value='';
           renewpd.value='';
           isdisable.value = false;
           isloading.value = false;
        }
      })
    };


    return {
      oldpw,
      newpd,
      renewpd,
      isdisable,
      isloading,
      validatorpassword,
      reconfigpassword,
      modifypassword,
    };
  }

  
};
</script>

<style lang="less">

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }

</style>
